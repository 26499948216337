import PropTypes from 'prop-types'

const CalendarRangeShape = PropTypes.shape({
  rangeType: PropTypes.string,
  label: PropTypes.node,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date)
})

export const CalendarDataShape = PropTypes.shape({
  ranges: PropTypes.arrayOf(CalendarRangeShape),
  selectedRange: CalendarRangeShape,
  comparisonType: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  comparisonStartDate: PropTypes.instanceOf(Date),
  comparisonEndDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  formattedDates: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    comparisonStartDate: PropTypes.string,
    comparisonEndDate: PropTypes.string
  })
})
